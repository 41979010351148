<template>
  <div>
    <v-row class="rebate-nav">
      <v-col cols="6" class="rebate-header-item pa-2" :class="$route.name === routeName.REBATE ? 'selected' : ''" @click="naviToRebate">
        <router-link :to="routeName.REBATE" style="text-decoration: unset !important">
          <span>{{ $t(`label.InstantRebate`) }}</span>
        </router-link>
      </v-col>
      <v-col cols="6" class="rebate-header-item pa-2" :class="$route.name === routeName.REBATE_HISTORY ? 'selected' : ''" @click="naviToRebateHistory">
        <router-link :to="routeName.REBATE_HISTORY" style="text-decoration: unset !important">
          <span>{{ $t(`label.RebateHistory`) }}</span>
        </router-link>
      </v-col>
    </v-row>

    <v-card class="ma-6 pa-4 rebate-card">
      <v-row>
        <v-col cols="12" class="d-flex py-2">
          <v-col cols="6" class="pa-0">
            <label class="rebate-history-date">{{ $t(`label.startDate`) }}</label>
            <v-col cols="12" class="pa-0 pr-3">
              <v-menu ref="startDateMenu" v-model="StartDateMenu" :close-on-content-click="true" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                <template v-slot:activator="{ on }">
                  <v-text-field v-model="startDateText" class="rebate-date" hide-details="true" v-on="on" append-icon="event"></v-text-field>
                </template>
                <v-date-picker :min="minDate" :max="maxDate" color="buttonPrimary" no-title v-model="dates" @input="StartDateMenu = false" range></v-date-picker>
              </v-menu>
            </v-col>
          </v-col>
          <v-col cols="6" class="pa-0 pl-3">
            <label class="rebate-history-date">{{ $t(`label.endDate`) }}</label>
            <v-col cols="12" class="pa-0">
              <v-menu ref="endDateMenu" v-model="EndDateMenu" :close-on-content-click="true" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                <template v-slot:activator="{ on }">
                  <v-text-field v-model="endDateText" class="rebate-date" hide-details="true" v-on="on" append-icon="event"></v-text-field>
                </template>
                <v-date-picker :min="minDate" :max="maxDate" color="buttonPrimary" no-title v-model="dates" @input="EndDateMenu = false" range></v-date-picker>
              </v-menu>
            </v-col>
          </v-col>
        </v-col>
        <v-col cols="12" class="text-center py-2 pb-4">
          <v-btn class="rebate-search-btn" @click="searchRebate">{{ $t(`label.search`) }}</v-btn>
        </v-col>
      </v-row>
    </v-card>

    <v-card class="ma-6 py-0 mt-4 rebate-card">
      <v-col class="pa-0">
        <v-col class="pa-3">
          <label>
            {{ $t(`label.rebateClaimedHistory`) }}
          </label>
        </v-col>

        <v-data-table
            :headers="rebateHeaders"
            :items="rebateHistory"
            class="rebate_table"
            mobile-breakpoint="0"
            :hide-default-footer="true"
            hide-default-header
            :no-data-text="$t('message.noRecordFound')"
        >
          <template v-slot:header>
            <tr class="rebate_table_header">
              <th class="text-start" style="padding: 10px 16px">
                Transaction Date
              </th>
              <th class="text-start" style="padding: 10px 16px">
                Amount
              </th>
            </tr>
          </template>
          <template v-slot:body="{ items }">
            <tr v-for="(item, index) in items" :key="index" @click="rebateDetail(item.claimed_at)">
              <td class="text-start" style="padding: 10px 16px">
                {{ item.claimed_at | longDate }}
              </td>
              <td class="text-start" style="padding: 10px 16px">
                {{ item.rebate_amount }}
              </td>
            </tr>
          </template>
        </v-data-table>
        <v-row class="mt-1" v-if="rebateHistoryPagination.last_page > 1">
          <v-col cols="12" class="text-center">
            <v-pagination
                v-model="rebateHistoryPagination.current_page"
                :length="rebateHistoryPagination.last_page"
                @input="changePage"
                :next-icon="'arrow_forward_ios'"
                :prev-icon="'arrow_back_ios'"
                :total-visible="10"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-col>
    </v-card>
  </div>
</template>

<script>
import { ROUTE_NAME } from '@/constants/route.constants'
import { REBATE_SUMMARY_HISTORY} from '@/store/bonus.module'
import { uiHelper } from '@/util'
import {SHARED} from "@/constants/constants";
import isEmpty from "lodash/isEmpty";
export default {
  name: "rebateHistory",

  data: () => ({
    routeName: ROUTE_NAME,
    dates: [SHARED.DEFAULT_START_DATE, SHARED.DEFAULT_END_DATE],
    dateOfBirthMenu: false,
    StartDateMenu: false,
    // StartDateText: '',
    EndDateMenu: false,
    currentCurrency: uiHelper.getCurrency(),
    rebateHeaders: [
      {
        text: 'Transaction Date',
        value: 'Transaction Date',
        class: 'caption'
      },
      {
        text: 'Amount',
        value: 'Amount',
        class: 'caption'
      }
    ],
    pagination: {
      currentPage: 1,
      totalPage: 1,
      paginationPageLimit: 10
    },
    minDate: SHARED.DEFAULT_PRIOR_THREE_MONTH_START_TIME,
    maxDate: SHARED.DEFAULT_START_TIME
  }),
  created() {
    this.getRebateHistory()
    this.metaTag()
  },
  computed: {
    startDateText: {
      get() {
        let startDate = this.dates[0] ? this.dates[0] : ''
        return startDate
      },
      set(dateRange) {
        this.dates = []
      }
    },
    endDateText: {
      get() {
        let endDate = this.dates[1] ? this.dates[1] : ''
        return endDate
      },
      set(dateRange) {
        this.dates = []
      }
    },
    rebateHistory() {
      return this.$store.state.bonus.rebateSummaryHistory.data
    },
    rebateHistoryPagination() {
      return this.$store.state.bonus.rebateSummaryHistory.pagination
    }
  },
  methods: {
    metaTag() {
      const currentUrl = window.location.href;
      uiHelper.setCanonical(currentUrl)
      const domainWithProtocol = currentUrl.split('/')[2];
      const domain = domainWithProtocol.replace(/(^\w+:|^)\/\//, '');
      const path = window.location.pathname;
      const cleanPath = path.replace(/\/(en-BD|bn-BD)/i, '');
      const Links = `https://${domain}${cleanPath}`;
      const Linksen = `https://${domain}/en-BD${cleanPath}`;
      const Linksbn = `https://${domain}/bn-BD${cleanPath}`;
      uiHelper.setHreflangLinks(Links)
      uiHelper.setHreflangLinksen(Linksen)
      uiHelper.setHreflangLinksbd(Linksbn)
    },
    goBack() {
      this.$router.push({ name: ROUTE_NAME.HOME })
    },
    naviToRebate() {
      this.$router.push({
        name: ROUTE_NAME.REBATE
      })
    },
    naviToRebateHistory() {
      this.$router.push({
        name: ROUTE_NAME.REBATE_HISTORY
      })
    },
    changePage(targetPage) {
      this.selectedPage = targetPage
    },
    selectedDate() {
      if (this.dates[0] && this.dates[1]) {
        setTimeout(function() {
          this.dateOfBirthMenu = false
        }, 500)
      }
    },
    searchRebate() {
      let obj = {
        startTime: this.dates[0],
        endTime: this.dates[1],
        size: 10,
        page: this.selectedPage
      }
      this.getRebateHistory(obj)
    },
    rebateDetail(claimed_date) {
      this.$router.push({
        name: ROUTE_NAME.REBATE_HISTORY_DETAIL,
        params: {
          claimed_date: claimed_date
        }
      })
    },
    getRebateHistory() {
      let rebateObj = {
        startTime: isEmpty(this.dates[0]) ? this.dates[0] : this.dates[0] + ' 00:00:00',
        endTime: isEmpty(this.dates[1]) ? this.dates[1] : this.dates[1] + ' 23:59:59',
        size: 10,
        page: this.selectedPage
      }
      this.$store.dispatch(`${REBATE_SUMMARY_HISTORY}`, {
        rebateObj
      })
    }
  }
}
</script>

<style lang="scss">
.instantrebate {
  background-image:linear-gradient(to bottom, #FFAB2D, #FF7700);
  text-transform: none;
  width: 50%;
  border-radius: unset;
}

.rebatehistory {
  background-color: darkblue !important;
  text-transform: none;
  width: 50%;
  border-radius: unset;
}

.rebate-search-btn {
  color: white !important;
  background-image:linear-gradient(to bottom, #FFAB2D, #FF7700);
  width: 100%;
}

.rebate-card {
  background: linear-gradient(0, #14805e, #116a4e 100%);
  color: #FFFFFF !important;
}

.rebate_table {
  border-radius: 0px;
  font-size: 14px;
}

.rebate-history-date {
  font-size: 14px;
}

.rebate-date {
  background-color: #ffffff !important;
  box-shadow: 0 0 3px 0px grey !important;
  border-radius: 6px;
  padding-top: 0px;
  padding-left: 10px;
}
</style>