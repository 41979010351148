<template>
  <div>
    <v-row>
      <v-col cols="12" class="rebate-header text-left">
        <v-icon class="rebate-header-navArrow" @click="goBack" large>mdi-chevron-left</v-icon>
        <span class="rebate-title">{{ $t(`label.rebateHistoryDetail`) }}</span>
      </v-col>
    </v-row>
    <v-row class="no-gutters rebateHD">
      <v-col class="pa-0 ma-5">
        <v-row class="mb-9">
          <v-col class="pa-3 col-12 rebate-detail-title">
            <label>
              {{ 'Transaction Date' }}
            </label>
          </v-col>
          <v-col class="pa-3 col-12 py-0 rebate-detail">
            <label>
              {{ rebateHistoryPeriod.end_period }}
            </label>
          </v-col>
        </v-row>
        <v-row class="mb-9">
          <v-col class="pa-3 col-12 rebate-detail-title">
            <label>
              {{ 'Rebate Period' }}
            </label>
          </v-col>
          <v-col class="pa-3 col-12 py-0 rebate-detail text-decoration-underline">
            <label>{{ rebateHistoryPeriod.start_period }} - {{ rebateHistoryPeriod.end_period }}</label>
          </v-col>
        </v-row>
        <v-row class="mb-9">
          <v-col class="pa-3 col-12 rebate-detail-title">
            <label>
              {{ 'Total Valid Bet' }}
            </label>
          </v-col>
          <v-col class="pa-3 col-12 py-0 rebate-detail">
            <label>
              {{ parseFloat(rebateHistoryAmount.total_valid_bet_amount).toFixed(2) }}
            </label>
          </v-col>
        </v-row>
        <v-row class="mb-9">
          <v-col class="pa-3 col-12 rebate-detail-title">
            <label>
              {{ 'Amount' }}
            </label>
          </v-col>
          <v-col class="pa-3 col-12 py-0 rebate-detail">
            <label>
              {{ parseFloat(rebateHistoryAmount.total_rebate_amount).toFixed(2) }}
            </label>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ROUTE_NAME } from '@/constants/route.constants'
import { REBATE_SUMMARY_DETAIL } from '@/store/bonus.module'
import { uiHelper } from '@/util'
import { SHARED } from '@/constants/constants'

export default {
  name: 'rebateHistory',

  data: () => ({
    routeName: ROUTE_NAME
  }),
  created() {
    this.getRebateHistoryDetail()
  },
  computed: {
    rebateHistoryDetail() {
      return this.$store.state.bonus.rebateSummaryDetail.data
    },
    rebateHistoryAmount() {
      return this.$store.state.bonus.rebateSummaryDetail.total
    },
    rebateHistoryPeriod() {
      return this.$store.state.bonus.rebateSummaryDetail.period_time
    }
  },
  methods: {
    goBack() {
      this.$router.push({ name: ROUTE_NAME.REBATE_HISTORY })
    },
    getRebateHistoryDetail() {
      let rebateObj = {
        claimed_date: this.$route.params.claimed_date
      }
      this.$store.dispatch(`${REBATE_SUMMARY_DETAIL}`, { rebateObj })
    }
  }
}
</script>

<style lang="scss">
.rebate-detail-title {
  font-size: 16px;
  font-weight: bold;
}

.rebate-detail {
  font-size: 14px;
  color: #8899a4;
}

.rebate-title {
  padding-left: 40px;
  font-weight: bold;
  font-size: 14px;
  font-family: 'Lato-SemiBold', sans-serif !important;
}

.rebate-history-title {
  font-weight: bold;
  font-family: 'Lato-SemiBold', sans-serif !important;
}

.rebateHD {
  background-color: #FFFFFF;
}

.rebate-header {
  position: relative;
  background: linear-gradient(to bottom, #FFAB2D, #FF7700);
  color: var(--v-newRebatePrimary-base);
  gap: 10px;
  margin-top: 10px;
  border-radius: 12px;
  //border: 1px solid #ffffff;
  box-shadow: 0 0 3px 0px grey !important;
}

.rebate-header-navArrow {
  color: #FFFFFF !important;
  padding: 0px 10px;
  position: absolute !important;
  left: 0;
  bottom: 7px;
}

.v-text-field > .v-input__control > .v-input__slot:before,
.v-text-field > .v-input__control > .v-input__slot:after {
  content: none;
}

.rebate_table_body {
  color: #9b9b9b;
}
</style>
