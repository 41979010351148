<template>
  <div class="rebateBg">
    <v-row class="rebate-nav">
      <v-col cols="6" class="rebate-header-item pa-2" :class="$route.name === routeName.REBATE ? 'selected' : ''" @click="naviToRebate">
        <router-link :to="routeName.REBATE" style="text-decoration: unset !important">
          <span>{{ $t(`label.InstantRebate`) }}</span>
        </router-link>
      </v-col>
      <v-col cols="6" class="rebate-header-item pa-2" :class="$route.name === routeName.REBATE_HISTORY ? 'selected' : ''" @click="naviToRebateHistory">
        <router-link :to="routeName.REBATE_HISTORY" style="text-decoration: unset !important">
          <span>{{ $t(`label.RebateHistory`) }}</span>
        </router-link>
      </v-col>
    </v-row>

    <v-card class="ma-6 py-2 rebateCard">
      <v-row>
        <v-col cols="12" class="text-center rebate-card-title pb-1">{{ $t(`label.totalPending`) }}</v-col>
        <v-col cols="8" class="mx-auto pa-0">
          <v-card class="claim-amount">
            <v-text class="pa-3">{{ isNaN(totalRebate.unclaimed) ? 0 : totalRebate.unclaimed | currency(currentCurrency) }}</v-text>
          </v-card>
        </v-col>
        <v-col cols="12" class=" pa-0 pb-1 text-center">
          <label class="rebate-card-desc">Last Updated - {{ lastClaimed.last_updated | emptyDataFormat }}</label>
        </v-col>
        <v-col cols="12" class="text-center pa-0">
          <v-btn :disabled="totalRebate.unclaimed <= 0" class="btn_claim" @click="claimRebate()">{{ $t(`label.claimAll`) }}</v-btn>
        </v-col>
        <v-col class=" pa-0 pb-3 text-center">
          <label class="rebate-card-desc">Last Claimed - {{ lastClaimed.last_claimed | emptyDataFormat }}</label>
        </v-col>
      </v-row>
    </v-card>

    <v-container class="px-5 pb-0 d-flex flex-column rebate-container-head" v-if="this.rebateProviderLd != null">
      <v-row>
        <v-col cols="12" class="d-flex my-3 mb-0 rebate-table">
          <label class="rebate-desc pl-2 font-weight-bold">Live Casino</label>
        </v-col>
      </v-row>
      <v-col cols="12" class="rebate-container mt-4" v-for="(item, index) in rebateProviderLd.slice(0, cardProviderNum.ldCard)" :key="index">
        <v-row>
          <v-col cols="6" class="rebate-desc border-1">Vip Level</v-col>
          <v-col cols="6" class="rebate-value border-2">{{ item.vip_level_name | emptyDataFormat }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="rebate-desc border-1">Provider</v-col>
          <v-col cols="6" class="rebate-value border-2">{{ item.provider_code }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="rebate-desc border-1">Rebate %</v-col>
          <v-col cols="6" class="rebate-value border-2">{{ item.vip_percentage }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="rebate-desc border-1">Valid Bet Amount</v-col>
          <v-col cols="6" class="rebate-value border-2">{{ item.valid_bet_amount }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="rebate-desc border-1">Rebate Earned</v-col>
          <v-col cols="6" class="rebate-value border-2">{{ item.rebate_amount }}</v-col>
        </v-row>
      </v-col>
      <v-col class="show-more-text px-0 pb-0">
        <span
            class="mt-3"
            @click="
            rebateProviderLd.length > cardProviderNum.ldCard
              ? showMore((ldCard = true), (rngCard = false), (sbCard = false), (tableCard = false), (cricketCard = false), (crashCard = false), (fishingCard = false), (lotteryCard = false))
              : showLess((ldCard = true), (rngCard = false), (sbCard = false), (tableCard = false), (cricketCard = false), (crashCard = false), (fishingCard = false), (lotteryCard = false))
          "
        >
          {{ rebateProviderLd.length > cardProviderNum.ldCard ? 'Show More' : 'Show Less' }}
        </span>
      </v-col>
    </v-container>

    <v-container class="px-5 pb-0 d-flex flex-column rebate-container-head" v-if="this.rebateProviderRng != null">
      <v-row>
        <v-col cols="12" class="d-flex my-3 mb-0 rebate-table">
          <label class="rebate-desc pl-2 font-weight-bold">Slots</label>
        </v-col>
      </v-row>
      <v-col cols="12" class="rebate-container mt-4" v-for="(item, index) in rebateProviderRng.slice(0, cardProviderNum.rngCard)" :key="index">
        <v-row>
          <v-col cols="6" class="rebate-desc border-1">Vip Level</v-col>
          <v-col cols="6" class="rebate-value border-2">{{ item.vip_level_name | emptyDataFormat }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="rebate-desc border-1">Provider</v-col>
          <v-col cols="6" class="rebate-value border-2">{{ item.provider_code }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="rebate-desc border-1">Rebate %</v-col>
          <v-col cols="6" class="rebate-value border-2">{{ item.vip_percentage }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="rebate-desc border-1">Valid Bet Amount</v-col>
          <v-col cols="6" class="rebate-value border-2">{{ item.valid_bet_amount }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="rebate-desc border-1">Rebate Earned</v-col>
          <v-col cols="6" class="rebate-value border-2">{{ item.rebate_amount }}</v-col>
        </v-row>
      </v-col>
      <v-col class="show-more-text px-0 pb-0">
        <span
            class="mt-3"
            @click="
            rebateProviderRng.length > cardProviderNum.rngCard
              ? showMore((ldCard = false), (rngCard = true), (sbCard = false), (tableCard = false), (cricketCard = false), (crashCard = false), (fishingCard = false), (lotteryCard = false))
              : showLess((ldCard = false), (rngCard = true), (sbCard = false), (tableCard = false), (cricketCard = false), (crashCard = false), (fishingCard = false), (lotteryCard = false))
          "
        >
          {{ rebateProviderRng.length > cardProviderNum.rngCard ? 'Show More' : 'Show Less' }}
        </span>
      </v-col>
    </v-container>

    <v-container class="px-5 pb-0 d-flex flex-column rebate-container-head" v-if="this.rebateProviderSb != null">
      <v-row>
        <v-col cols="12" class="d-flex my-3 mb-0 rebate-table">
          <label class="rebate-desc pl-2 font-weight-bold">Sports</label>
        </v-col>
      </v-row>
      <v-col cols="12" class="rebate-container mt-4" v-for="(item, index) in rebateProviderSb.slice(0, cardProviderNum.sbCard)" :key="index">
        <v-row>
          <v-col cols="6" class="rebate-desc border-1">Vip Level</v-col>
          <v-col cols="6" class="rebate-value border-2">{{ item.vip_level_name | emptyDataFormat }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="rebate-desc border-1">Provider</v-col>
          <v-col cols="6" class="rebate-value border-2">{{ item.provider_code }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="rebate-desc border-1">Rebate %</v-col>
          <v-col cols="6" class="rebate-value border-2">{{ item.vip_percentage }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="rebate-desc border-1">Valid Bet Amount</v-col>
          <v-col cols="6" class="rebate-value border-2">{{ item.valid_bet_amount }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="rebate-desc border-1">Rebate Earned</v-col>
          <v-col cols="6" class="rebate-value border-2">{{ item.rebate_amount }}</v-col>
        </v-row>
      </v-col>
      <v-col class="show-more-text px-0 pb-0">
        <span
            class="mt-3"
            @click="
            rebateProviderSb.length > cardProviderNum.sbCard
              ? showMore((ldCard = false), (rngCard = false), (sbCard = true), (tableCard = false), (cricketCard = false), (crashCard = false), (fishingCard = false), (lotteryCard = false))
              : showLess((ldCard = false), (rngCard = false), (sbCard = true), (tableCard = false), (cricketCard = false), (crashCard = false), (fishingCard = false), (lotteryCard = false))
          "
        >
          {{ rebateProviderSb.length > cardProviderNum.sbCard ? 'Show More' : 'Show Less' }}
        </span>
      </v-col>
    </v-container>

    <v-container class="px-5 pb-0 d-flex flex-column rebate-container-head" v-if="this.rebateProviderTable != null">
      <v-row>
        <v-col cols="12" class="d-flex my-3 mb-0 rebate-table">
          <label class="rebate-desc pl-2 font-weight-bold">Table</label>
        </v-col>
      </v-row>
      <v-col cols="12" class="rebate-container mt-4" v-for="(item, index) in rebateProviderTable.slice(0, cardProviderNum.tableCard)" :key="index">
        <v-row>
          <v-col cols="6" class="rebate-desc border-1">Vip Level</v-col>
          <v-col cols="6" class="rebate-value border-2">{{ item.vip_level_name | emptyDataFormat }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="rebate-desc border-1">Provider</v-col>
          <v-col cols="6" class="rebate-value border-2">{{ item.provider_code }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="rebate-desc border-1">Rebate %</v-col>
          <v-col cols="6" class="rebate-value border-2">{{ item.vip_percentage }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="rebate-desc border-1">Valid Bet Amount</v-col>
          <v-col cols="6" class="rebate-value border-2">{{ item.valid_bet_amount }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="rebate-desc border-1">Rebate Earned</v-col>
          <v-col cols="6" class="rebate-value border-2">{{ item.rebate_amount }}</v-col>
        </v-row>
      </v-col>
      <v-col class="show-more-text px-0 pb-0">
        <span
            class="mt-3"
            @click="
            rebateProviderTable.length > cardProviderNum.tableCard
              ? showMore((ldCard = false), (rngCard = false), (sbCard = false), (tableCard = true), (cricketCard = false), (crashCard = false), (fishingCard = false), (lotteryCard = false))
              : showLess((ldCard = false), (rngCard = false), (sbCard = false), (tableCard = true), (cricketCard = false), (crashCard = false), (fishingCard = false), (lotteryCard = false))
          "
        >
          {{ rebateProviderTable.length > cardProviderNum.tableCard ? 'Show More' : 'Show Less' }}
        </span>
      </v-col>
    </v-container>

    <v-container class="px-5 pb-0 d-flex flex-column rebate-container-head" v-if="this.rebateProviderCricket != null">
      <v-row>
        <v-col cols="12" class="d-flex my-3 mb-0 rebate-table">
          <label class="rebate-desc pl-2 font-weight-bold">Cricket</label>
        </v-col>
      </v-row>
      <v-col cols="12" class="rebate-container mt-4" v-for="(item, index) in rebateProviderCricket.slice(0, cardProviderNum.cricketCard)" :key="index">
        <v-row>
          <v-col cols="6" class="rebate-desc border-1">Vip Level</v-col>
          <v-col cols="6" class="rebate-value border-2">{{ item.vip_level_name | emptyDataFormat }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="rebate-desc border-1">Provider</v-col>
          <v-col cols="6" class="rebate-value border-2">{{ item.provider_code }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="rebate-desc border-1">Rebate %</v-col>
          <v-col cols="6" class="rebate-value border-2">{{ item.vip_percentage }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="rebate-desc border-1">Valid Bet Amount</v-col>
          <v-col cols="6" class="rebate-value border-2">{{ item.valid_bet_amount }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="rebate-desc border-1">Rebate Earned</v-col>
          <v-col cols="6" class="rebate-value border-2">{{ item.rebate_amount }}</v-col>
        </v-row>
      </v-col>
      <v-col class="show-more-text px-0 pb-0">
        <span
            class="mt-3"
            @click="
            rebateProviderCricket.length > cardProviderNum.cricketCard
              ? showMore((ldCard = false), (rngCard = false), (sbCard = false), (tableCard = false), (cricketCard = true), (crashCard = false), (fishingCard = false), (lotteryCard = false))
              : showLess((ldCard = false), (rngCard = false), (sbCard = false), (tableCard = false), (cricketCard = true), (crashCard = false), (fishingCard = false), (lotteryCard = false))
          "
        >
          {{ rebateProviderCricket.length > cardProviderNum.cricketCard ? 'Show More' : 'Show Less' }}
        </span>
      </v-col>
    </v-container>

    <v-container class="px-5 pb-0 d-flex flex-column rebate-container-head" v-if="this.rebateProviderCrash != null">
      <v-row>
        <v-col cols="12" class="d-flex my-3 mb-0 rebate-table">
          <label class="rebate-desc pl-2 font-weight-bold">Crash</label>
        </v-col>
      </v-row>
      <v-col cols="12" class="rebate-container mt-4" v-for="(item, index) in rebateProviderCrash.slice(0, cardProviderNum.crashCard)" :key="index">
        <v-row>
          <v-col cols="6" class="rebate-desc border-1">Vip Level</v-col>
          <v-col cols="6" class="rebate-value border-2">{{ item.vip_level_name | emptyDataFormat }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="rebate-desc border-1">Provider</v-col>
          <v-col cols="6" class="rebate-value border-2">{{ item.provider_code }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="rebate-desc border-1">Rebate %</v-col>
          <v-col cols="6" class="rebate-value border-2">{{ item.vip_percentage }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="rebate-desc border-1">Valid Bet Amount</v-col>
          <v-col cols="6" class="rebate-value border-2">{{ item.valid_bet_amount }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="rebate-desc border-1">Rebate Earned</v-col>
          <v-col cols="6" class="rebate-value border-2">{{ item.rebate_amount }}</v-col>
        </v-row>
      </v-col>
      <v-col class="show-more-text px-0">
        <span
            class="mt-3"
            @click="
            rebateProviderCrash.length > cardProviderNum.crashCard
              ? showMore((ldCard = false), (rngCard = false), (sbCard = false), (tableCard = false), (cricketCard = false), (crashCard = true), (fishingCard = false), (lotteryCard = false))
              : showLess((ldCard = false), (rngCard = false), (sbCard = false), (tableCard = false), (cricketCard = false), (crashCard = true), (fishingCard = false), (lotteryCard = false))
          "
        >
          {{ rebateProviderCrash.length > cardProviderNum.crashCard ? 'Show More' : 'Show Less' }}
        </span>
      </v-col>
    </v-container>

    <v-container class="px-5 pb-0 d-flex flex-column rebate-container-head" v-if="this.rebateProviderFishing != null">
      <v-row>
        <v-col cols="12" class="d-flex my-3 mb-0 rebate-table">
          <label class="rebate-desc pl-2 font-weight-bold">Fishing</label>
        </v-col>
      </v-row>
      <v-col cols="12" class="rebate-container mt-4" v-for="(item, index) in rebateProviderFishing.slice(0, cardProviderNum.fishingCard)" :key="index">
        <v-row>
          <v-col cols="6" class="rebate-desc border-1">Vip Level</v-col>
          <v-col cols="6" class="rebate-value border-2">{{ item.vip_level_name | emptyDataFormat }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="rebate-desc border-1">Provider</v-col>
          <v-col cols="6" class="rebate-value border-2">{{ item.provider_code }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="rebate-desc border-1">Rebate %</v-col>
          <v-col cols="6" class="rebate-value border-2">{{ item.vip_percentage }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="rebate-desc border-1">Valid Bet Amount</v-col>
          <v-col cols="6" class="rebate-value border-2">{{ item.valid_bet_amount }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="rebate-desc border-1">Rebate Earned</v-col>
          <v-col cols="6" class="rebate-value border-2">{{ item.rebate_amount }}</v-col>
        </v-row>
      </v-col>
      <v-col class="show-more-text px-0">
        <span
            class="mt-3"
            @click="
            rebateProviderFishing.length > cardProviderNum.fishingCard
              ? showMore((ldCard = false), (rngCard = false), (sbCard = false), (tableCard = false), (cricketCard = false), (crashCard = false), (fishingCard = true), (lotteryCard = false))
              : showLess((ldCard = false), (rngCard = false), (sbCard = false), (tableCard = false), (cricketCard = false), (crashCard = false), (fishingCard = true), (lotteryCard = false))
          "
        >
          {{ rebateProviderFishing.length > cardProviderNum.fishingCard ? 'Show More' : 'Show Less' }}
        </span>
      </v-col>
    </v-container>

    <v-container class="px-5 pb-0 d-flex flex-column rebate-container-head" v-if="this.rebateProviderLottery != null">
      <v-row>
        <v-col cols="12" class="d-flex my-3 mb-0 rebate-table">
          <label class="rebate-desc pl-2 font-weight-bold">Lottery</label>
        </v-col>
      </v-row>
      <v-col cols="12" class="rebate-container mt-4" v-for="(item, index) in rebateProviderLottery.slice(0, cardProviderNum.lotteryCard)" :key="index">
        <v-row>
          <v-col cols="6" class="rebate-desc border-1">Vip Level</v-col>
          <v-col cols="6" class="rebate-value border-2">{{ item.vip_level_name | emptyDataFormat }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="rebate-desc border-1">Provider</v-col>
          <v-col cols="6" class="rebate-value border-2">{{ item.provider_code }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="rebate-desc border-1">Rebate %</v-col>
          <v-col cols="6" class="rebate-value border-2">{{ item.vip_percentage }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="rebate-desc border-1">Valid Bet Amount</v-col>
          <v-col cols="6" class="rebate-value border-2">{{ item.valid_bet_amount }}</v-col>
        </v-row>
        <v-row>
          <v-col cols="6" class="rebate-desc border-1">Rebate Earned</v-col>
          <v-col cols="6" class="rebate-value border-2">{{ item.rebate_amount }}</v-col>
        </v-row>
      </v-col>
      <v-col class="show-more-text px-0">
        <span
            class="mt-3"
            @click="
            rebateProviderLottery.length > cardProviderNum.lotteryCard
              ? showMore((ldCard = false), (rngCard = false), (sbCard = false), (tableCard = false), (cricketCard = false), (crashCard = false), (fishingCard = false), (lotteryCard = true))
              : showLess((ldCard = false), (rngCard = false), (sbCard = false), (tableCard = false), (cricketCard = false), (crashCard = false), (fishingCard = false), (lotteryCard = true))
          "
        >
          {{ rebateProviderLottery.length > cardProviderNum.lotteryCard ? 'Show More' : 'Show Less' }}
        </span>
      </v-col>
    </v-container>
  </div>
</template>

<script>
import { DevicePlatform } from '@/constants/enums'
import { ROUTE_NAME } from '@/constants/route.constants'
import { uiHelper, locale, formValidator } from '@/util'
import { MEMBER_DETAIL } from '@/store/member.module'
import { CLAIM_REBATE, REBATE_HISTORY, REBATE_PROVIDER, RESET_CLAIM_REBATE } from '@/store/bonus.module'
export default {
  name: 'instantRebate',
  data: () => ({
    dailyRewardsDialogShow: false,
    dateMenu: false,
    dates: ['', ''],
    alertShow: false,
    alertSuccess: false,
    alertMessage: '',
    dayOfWeekNumber: new Date().getDay(),
    devicePlatform: DevicePlatform,
    validator: formValidator,
    currentCurrency: uiHelper.getCurrency(),
    routeName: ROUTE_NAME,
    days: ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat'],
    pagination: {},
    selectedPage: 1,
    selectedItem: null,
    cardProviderNum: {
      ldCard: 1,
      rngCard: 1,
      sbCard: 1,
      tableCard: 1,
      cricketCard: 1,
      crashCard: 1,
      fishingCard: 1,
      lotteryCard: 1
    },
    ldCard: false,
    rngCard: false,
    sbCard: false,
    tableCard: false,
    cricketCard: false,
    crashCard: false,
    fishingCard: false,
    lotteryCard: false
  }),
  created() {
    this.getMemberDetail()
    this.getRebateHistory()
    this.getRebateProvider()
    this.metaTag()
  },
  computed: {
    dateRangeText: {
      get() {
        let startDate = this.dates[0] ? this.dates[0] : ''
        let endDate = this.dates[1] ? this.dates[1] : ''
        return startDate + ' - ' + endDate
      },
      set(dateRange) {
        this.dates = []
      }
    },
    claimRebateResult() {
      return this.$store.state.bonus.claimRebateResult.complete
    },
    totalRebate() {
      return this.$store.state.bonus.rebateHistory.totalRebate
    },
    lastClaimed() {
      return this.$store.state.bonus.rebateHistory.lastClaimed
    },
    memberInfo() {
      return this.$store.state.member.info
    },
    isLoggedIn() {
      return this.$store.state.member.isLoggedIn
    },
    rebateProviderLd() {
      return this.$store.state.bonus.rebateProviderList.LD
    },
    rebateProviderRng() {
      return this.$store.state.bonus.rebateProviderList.RNG
    },
    rebateProviderSb() {
      return this.$store.state.bonus.rebateProviderList.SB
    },
    rebateProviderTable() {
      return this.$store.state.bonus.rebateProviderList.TABLE
    },
    rebateProviderCricket() {
      return this.$store.state.bonus.rebateProviderList.CRICKET
    },
    rebateProviderCrash() {
      return this.$store.state.bonus.rebateProviderList.CRASH
    },
    rebateProviderFishing() {
      return this.$store.state.bonus.rebateProviderList.FISHING
    },
    rebateProviderLottery() {
      return this.$store.state.bonus.rebateProviderList.LOTTERY
    }
  },
  watch: {
    claimRebateResult() {
      if (this.$store.state.bonus.claimRebateResult.complete) {
        this.claimRebateResponse(this.$store.state.bonus.claimRebateResult)
      }
    }
  },
  methods: {
    metaTag() {
      const currentUrl = window.location.href;
      uiHelper.setCanonical(currentUrl)
      const domainWithProtocol = currentUrl.split('/')[2];
      const domain = domainWithProtocol.replace(/(^\w+:|^)\/\//, '');
      const path = window.location.pathname;
      const cleanPath = path.replace(/\/(en-BD|bn-BD)/i, '');
      const Links = `https://${domain}${cleanPath}`;
      const Linksen = `https://${domain}/en-BD${cleanPath}`;
      const Linksbn = `https://${domain}/bn-BD${cleanPath}`;
      uiHelper.setHreflangLinks(Links)
      uiHelper.setHreflangLinksen(Linksen)
      uiHelper.setHreflangLinksbd(Linksbn)
    },
    showMore(ld, rng, sb, table, cricket, crash, fishing, lottery) {
      if (ld) {
        this.cardProviderNum.ldCard = this.rebateProviderLd.length == null ? 1 : this.rebateProviderLd.length
      } else if (rng) {
        this.cardProviderNum.rngCard = this.rebateProviderRng.length == null ? 1 : this.rebateProviderRng.length
      } else if (sb) {
        this.cardProviderNum.sbCard = this.rebateProviderSb.length == null ? 1 : this.rebateProviderSb.length
      } else if (table) {
        this.cardProviderNum.tableCard = this.rebateProviderTable.length == null ? 1 : this.rebateProviderTable.length
      } else if (cricket) {
        this.cardProviderNum.cricketCard = this.rebateProviderCricket.length == null ? 1 : this.rebateProviderCricket.length
      } else if (crash) {
        this.cardProviderNum.crashCard = this.rebateProviderCrash.length == null ? 1 : this.rebateProviderCrash.length
      } else if (fishing) {
        this.cardProviderNum.fishingCard = this.rebateProviderFishing.length == null ? 1 : this.rebateProviderFishing.length
      } else if (lottery) {
        this.cardProviderNum.lotteryCard = this.rebateProviderLottery.length == null ? 1 : this.rebateProviderLottery.length
      }
    },
    showLess(ld, rng, sb, table, cricket, crash, fishing, lottery) {
      if (ld) {
        this.cardProviderNum.ldCard = 1
      } else if (rng) {
        this.cardProviderNum.rngCard = 1
      } else if (sb) {
        this.cardProviderNum.sbCard = 1
      } else if (table) {
        this.cardProviderNum.tableCard = 1
      } else if (cricket) {
        this.cardProviderNum.cricketCard = 1
      } else if (crash) {
        this.cardProviderNum.crashCard = 1
      } else if (fishing) {
        this.cardProviderNum.fishingCard = 1
      } else if (lottery) {
        this.cardProviderNum.lotteryCard = 1
      }
    },
    goBack() {
      this.$router.push({ name: ROUTE_NAME.HOME })
    },
    naviToRebate() {
      this.$router.push({
        name: ROUTE_NAME.REBATE
      })
    },
    naviToRebateHistory() {
      this.$router.push({
        name: ROUTE_NAME.REBATE_HISTORY
      })
    },
    selectedDate() {
      if (this.dates[0] && this.dates[1]) {
        let self = this
        setTimeout(function() {
          self.dateMenu = false
        }, 500)
      }
    },
    claimRebate() {
      this.$store.dispatch(`${CLAIM_REBATE}`)
    },
    claimRebateResponse(result) {
      if (result.success && result.code == 0) {
        this.openAlert(true, locale.getMessage(`message.rebateClaimedSuccess`))
      } else {
        this.openAlert(false, locale.getMessage(`message.rebateClaimedFailed`))
      }
      this.getRebateHistory()
      this.getRebateProvider()
      this.$store.dispatch(`${RESET_CLAIM_REBATE}`)
    },
    getRebateHistory() {
      let obj = {
        startTime: null,
        endTime: null,
        page: 1,
        size: 10000
      }
      this.$store.dispatch(`${REBATE_HISTORY}`, { obj })
    },
    getRebateProvider() {
      this.$store.dispatch(`${REBATE_PROVIDER}`)
    },
    openAlert(success, msg) {
      this.alertSuccess = success
      this.alertMessage = msg
      this.alertShow = true
    },
    getMemberDetail() {
      if (this.isLoggedIn) this.$store.dispatch(`${MEMBER_DETAIL}`)
    }
  }
}
</script>

<style lang="scss">
.rewards-card {
  height: 75%;
  overflow-x: hidden;
  overflow-y: scroll;
}

.reward_log_pagination .v-pagination {
  li {
    border: none !important;

    button {
      border: none !important;
      font-size: 14px;
    }
  }
}

.reward-table-header {
  color: #acacac !important;
}

.rewards-date-search {
  width: fit-content;
  margin-right: 0px;
  float: right !important;

  .v-input__slot {
    min-height: 40px !important;
    max-width: 250px;
  }

  .v-input__append-inner {
    margin-top: 8px;
  }
}

.reward-icon-label {
  color: #3a3a3a;
  font-size: 12px;
}

.coin-divider {
  margin-top: 33px;
}

.coin-divider.v-divider {
  border-color: #acacac;
  border-width: 1px !important;
}

.daily-coins-progress {
  font-size: 12px !important;
}

.daily-rewards-card {
  .dialog-title {
    font-size: 20px;
  }

  .dialog-subtitle {
    color: #acacac;
    font-size: 14px;
  }

  .dialog-header {
    position: relative;
  }

  .dialog-close-icon {
    position: absolute;
    top: 70% !important;
    font-size: 30px !important;
    cursor: pointer;
    transform: translateY(-50%);

    &.register,
    &.login {
      top: 23%;
    }
  }

  .dialog-row {
    overflow-y: scroll;
  }

  .dialog-divider {
    border-width: 0.8px !important;
    border-color: #acacac !important;
    height: 100%;
  }
}

.daily-rewards-dialog.v-dialog {
  margin: 24px;
}

.daily-rewards-dialog.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 95%;
}

.rebate-nav {
  margin-top: 0px;
}

.rebateBg {
  //background-color: #f3f5f6;
}

.rebate-header-item {
  text-align: center;
  background-color: #0d543e;
  text-transform: none;
  width: 50%;
  border-radius: unset;
  color: #ffffff;
  font-size: 14px;

  &.selected {
    background-color: #33af83 !important;
    text-transform: none;
    width: 50%;
    border-radius: unset;
    color: #ffffff;
  }
}

.rebateCard {
  background: linear-gradient(0, #14805e, #116a4e 100%);
  text-align: center;
  color: #ffffff !important;
  border-radius: 12px;
}
.claim-amount {
  text-align: center;
  background-color: #ffffff !important;
}

.rebate-card-desc {
  font-size: 9px;
  color: #ffffff;
}

.pendingclaim {
  background-color: white;
  width: 50px;
  color: darkblue;
  text-align: center;
  width: 150px;
  height: 30px;
  font-size: 17px;
  cursor: pointer;
}

.btn_claim {
  width: 150px;
  height: 30px !important;
  color: white !important;
  background-image: linear-gradient(to bottom, #ffab2d, #ff7700);
  text-transform: none;
}

.game_btn_row {
  display: flex;
  justify-content: space-between;
  width: 380px;
  margin: 5px;
  font-size: 12px;
  cursor: pointer;
}

.gamebutton:focus,
.gamebutton:active {
  border-radius: 8px;
  color: white;
  background-image: linear-gradient(to bottom, #ffab2d, #ff7700);
  padding: 3px;
}

.date_selection {
  padding: 5px;
  margin: 15px 5px 5px 20px;
  color: darkblue;
  background-color: lightgrey;
  font-size: 12px;
  border: 2px solid black;
  border-radius: 5px;
  width: 150px;
  height: 30px;
}

.provider_selection {
  padding: 5px;
  margin: 15px 5px 5px 20px;
  color: darkblue;
  background-color: lightgrey;
  font-size: 12px;
  border: 2px solid black;
  border-radius: 5px;
  width: 150px;
  height: 30px;
}

.rebate-container {
  background-color: #ffffff !important;
  border: 1px solid #ffffff;
  box-shadow: 0 0 3px 0px grey !important;
  border-radius: 6px;
  //margin-top: 20px;
  //border-collapse: collapse;
  //border: 1px solid black;
  //width: 350px;
  //margin: 5px;
}

.rebate_details {
  text-transform: none;
  margin: 5px;
  //text-align: left;
  font-size: 12px;
  width: 100%;
  padding: 11px;
}

.rebate_desc {
  text-align: left;
  font-size: 14px;
}

.rebate_value {
  text-align: center;
  font-size: 14px;
  color: gray;
}

.rebate-container {
  background-color: #ffffff !important;
  border: 1px solid #ffffff;
  box-shadow: 0 0 3px 0px grey !important;
  border-radius: 6px;
}

.show-more-text {
  font-size: 12px;
  color: cornflowerblue;
  font-weight: bold;
  text-decoration: underline;
  text-align: end;
}

.rebate-value {
  text-align: center;
  font-family: 'Lato-SemiBold', sans-serif !important;
  color: #9b9b9b;
}

.rebate-desc {
  font-family: 'Lato-SemiBold', sans-serif !important;
  text-align: left;
}

.rebate-container-head {
  align-items: normal !important;
  text-transform: capitalize !important;
}

.rebate-card-desc {
  font-size: 9px;
  color: #ffffff;
}

.border-1 {
  border-bottom: 1px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
}

.border-2 {
  border-bottom: 1px solid #d9d9d9;
}

@media (max-width: 599px) {
  .rewards-card {
    height: fit-content;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  .mobile-reward-table {
    font-size: 12px;
  }
  .rewards-date-search {
    width: 100%;
    margin-right: 0px;
    float: right !important;

    .v-input__slot {
      min-height: 40px !important;
      max-width: 100%;
    }

    .v-input__append-inner {
      margin-top: 8px;
    }
  }
  .daily-rewards-dialog.v-dialog {
    margin: 16px;
  }
  .daily-rewards-card {
    .dialog-title {
      font-size: 16px !important;
    }

    .dialog-subtitle {
      color: #acacac;
      font-size: 12px;
    }
  }
}
</style>
